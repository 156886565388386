<template>
	<modal class="NoxModalTreeGifts" name="NoxModalTreeGifts" modal-width="600" :width="600" :height="'auto'" :adaptive="true" :scrollable="true" :clickToClose="true" @before-open="beforeOpen">
		<div class="nox_modal">
			<div class="nox_modal_head">
				<div class="nox_modal_close" @click="close"><font-awesome-icon :icon="['fas', 'times']" /></div>
				<div class="nox_modal_title">
					<span v-if="noxType == 'editGift'">Редактирование заявки</span>
					<span v-else-if="noxType == 'detailsGift'" v-html="$store.getters.getLanguageText('5.13', 0)"></span>
					<span v-else-if="noxType == 'receiveGift'" v-html="$store.getters.getLanguageText('5.13', 8)"></span>
					<span v-else-if="noxType == 'deliveryGift'" v-html="$store.getters.getLanguageText('5.13', 46)"></span>
				</div>
			</div>
			<div class="nox_modal_body">
				<form class="nox_modal_form">
					<div class="nox_modal_info">
						<div v-if="noxType == 'editGift'">
							<div class="nox_modal_info_row">
								<div class="nox_modal_info_row_label">NOX ID:</div>
								<div class="nox_modal_info_row_value">
									<div class="nox_modal_info_row_value_text">{{ noxUserId }}</div>
								</div>
							</div>
							<div class="nox_modal_info_row">
								<div class="nox_modal_info_row_label" v-html="$store.getters.getLanguageText('5.13', 13)"></div>
								<div class="nox_modal_info_row_value">
									<div class="nox_modal_info_row_value_text" v-html="$store.getters.getLanguageText('3.5.5', (noxGiftId - 1))"></div>
								</div>
							</div>
							<div class="nox_modal_info_row">
								<div class="nox_modal_info_row_label" v-html="$store.getters.getLanguageText('5.13', 14)"></div>
								<div class="nox_modal_info_row_value">
									<div class="nox_modal_info_row_value_text">{{ new Date(noxClosedAt * 1000).toLocaleString($store.state.noxLanguage) }}</div>
								</div>
							</div>
							<div class="nox_modal_info_row">
								<div class="nox_modal_info_row_label">Дата подачи заявки:</div>
								<div class="nox_modal_info_row_value">
									<div class="nox_modal_info_row_value_text">{{ new Date(noxCreatedAt * 1000).toLocaleString($store.state.noxLanguage) }}</div>
								</div>
							</div>
							<div class="nox_modal_info_row margin_top_20">
								<div class="nox_modal_info_row_label blue">CDEK id:</div>
								<div class="nox_modal_info_row_value">
									<input type="text" class="nox_input" v-model.trim="noxCdekId" placeholder="номер заказа CDEK" autocomplete="off">
									<div v-html="noxAlertCdekId"></div>
								</div>
							</div>
							<div class="nox_modal_info_row">
								<div class="nox_modal_info_row_label green">Статус заявки:</div>
								<div class="nox_modal_info_row_value">
									<v-select class="nox_select style-chooser" v-model.trim="noxStatus" placeholder="выберите статус заявки" :options="$parent.$parent.$parent.getSelectOptions(6)"></v-select>
									<div v-html="noxAlertStatus"></div>
								</div>
							</div>
							<div class="nox_modal_info_row">
								<div class="nox_modal_info_row_label" v-html="$store.getters.getLanguageText('5.13', 15)"></div>
								<div class="nox_modal_info_row_value">
									<input type="text" class="nox_input" v-model.trim="noxName" :placeholder="$store.getters.getLanguageText('5.13', 16)" autocomplete="off">
									<div v-html="noxAlertName"></div>
								</div>
							</div>
							<div class="nox_modal_info_row">
								<div class="nox_modal_info_row_label" v-html="$store.getters.getLanguageText('5.13', 17)"></div>
								<div class="nox_modal_info_row_value">
									<input type="text" class="nox_input" v-model.trim="noxSurname" :placeholder="$store.getters.getLanguageText('5.13', 18)" autocomplete="off">
									<div v-html="noxAlertSurname"></div>
								</div>
							</div>
							<div class="nox_modal_info_row">
								<div class="nox_modal_info_row_label" v-html="$store.getters.getLanguageText('5.13', 19)"></div>
								<div class="nox_modal_info_row_value">
									<input type="text" class="nox_input" v-model.trim="noxMiddlename" :placeholder="$store.getters.getLanguageText('5.13', 20)" autocomplete="off">
									<div v-html="noxAlertMiddlename"></div>
								</div>
							</div>
							<div class="nox_modal_info_row">
								<div class="nox_modal_info_row_label" v-html="$store.getters.getLanguageText('5.13', 21)"></div>
								<div class="nox_modal_info_row_value">
									<input type="text" class="nox_input" v-model.trim="noxEmail" :placeholder="$store.getters.getLanguageText('5.13', 22)" autocomplete="off">
									<div v-html="noxAlertEmail"></div>
								</div>
							</div>
							<div class="nox_modal_info_row">
								<div class="nox_modal_info_row_label" v-html="$store.getters.getLanguageText('5.13', 23)"></div>
								<div class="nox_modal_info_row_value">
									<input type="text" class="nox_input" v-model.trim="noxPhone" :placeholder="$store.getters.getLanguageText('5.13', 24)" autocomplete="off">
									<div v-html="noxAlertPhone"></div>
								</div>
							</div>
							<div class="nox_modal_info_row">
								<div class="nox_modal_info_row_label" v-html="$store.getters.getLanguageText('5.13', 25)"></div>
								<div class="nox_modal_info_row_value">
									<v-select class="nox_select style-chooser" v-model.trim="noxCountry" :placeholder="$store.getters.getLanguageText('5.13', 26)" :options="$parent.$parent.$parent.getSelectOptions(1)"></v-select>
									<div v-html="noxAlertCountry"></div>
								</div>
							</div>
							<div class="nox_modal_info_row">
								<div class="nox_modal_info_row_label" v-html="$store.getters.getLanguageText('5.13', 27)"></div>
								<div class="nox_modal_info_row_value">
									<input type="text" class="nox_input" v-model.trim="noxState" :placeholder="$store.getters.getLanguageText('5.13', 28)" autocomplete="off">
									<div v-html="noxAlertState"></div>
								</div>
							</div>
							<div class="nox_modal_info_row">
								<div class="nox_modal_info_row_label" v-html="$store.getters.getLanguageText('5.13', 29)"></div>
								<div class="nox_modal_info_row_value">
									<input type="text" class="nox_input" v-model.trim="noxCity" :placeholder="$store.getters.getLanguageText('5.13', 30)" autocomplete="off">
									<div v-html="noxAlertCity"></div>
								</div>
							</div>
							<div class="nox_modal_info_row">
								<div class="nox_modal_info_row_label" v-html="$store.getters.getLanguageText('5.13', 31)"></div>
								<div class="nox_modal_info_row_value">
									<input type="text" class="nox_input" v-model.trim="noxStreet" :placeholder="$store.getters.getLanguageText('5.13', 32)" autocomplete="off">
									<div v-html="noxAlertStreet"></div>
								</div>
							</div>
							<div class="nox_modal_info_row">
								<div class="nox_modal_info_row_label" v-html="$store.getters.getLanguageText('5.13', 33)"></div>
								<div class="nox_modal_info_row_value">
									<input type="text" class="nox_input" v-model.trim="noxHouse" :placeholder="$store.getters.getLanguageText('5.13', 34)" autocomplete="off">
									<div v-html="noxAlertHouse"></div>
								</div>
							</div>
							<div class="nox_modal_info_row">
								<div class="nox_modal_info_row_label" v-html="$store.getters.getLanguageText('5.13', 35)"></div>
								<div class="nox_modal_info_row_value">
									<input type="text" class="nox_input" v-model.trim="noxHousing" :placeholder="$store.getters.getLanguageText('5.13', 36)" autocomplete="off">
									<div v-html="noxAlertHousing"></div>
								</div>
							</div>
							<div class="nox_modal_info_row">
								<div class="nox_modal_info_row_label" v-html="$store.getters.getLanguageText('5.13', 37)"></div>
								<div class="nox_modal_info_row_value">
									<input type="text" class="nox_input" v-model.trim="noxApartment" :placeholder="$store.getters.getLanguageText('5.13', 38)" autocomplete="off">
									<div v-html="noxAlertApartment"></div>
								</div>
							</div>
							<div class="nox_modal_info_row">
								<div class="nox_modal_info_row_label" v-html="$store.getters.getLanguageText('5.13', 39)"></div>
								<div class="nox_modal_info_row_value">
									<input type="text" class="nox_input" v-model.trim="noxPostcode" :placeholder="$store.getters.getLanguageText('5.13', 40)" autocomplete="off">
									<div v-html="noxAlertPostcode"></div>
								</div>
							</div>
							<div class="nox_modal_info_row last">
								<div class="nox_modal_info_row_label" v-html="$store.getters.getLanguageText('5.13', 41)"></div>
								<div class="nox_modal_info_row_value">
									<textarea class="nox_textarea small" v-model.trim="noxComment" :placeholder="$store.getters.getLanguageText('5.13', 42)"></textarea>
									<div v-html="noxAlertComment"></div>
								</div>
							</div>
						</div>
						<div v-else-if="noxType == 'detailsGift'">
							<div class="nox_modal_info_tree_gift_details">
								<div class="nox_modal_info_tree_gift_details_image"><img :src="require(`@/assets/images/gift_${noxId}.png`)"></div>
								<p v-if="$parent.noxTreeBonuses.bonus_6_details[noxId] && $parent.noxTreeBonuses.bonus_6_details[noxId].status == 1" v-html="$store.getters.getLanguageText('5.13', 1, { name: $store.getters.getLanguageText('3.5.5', noxIndex) })"></p>
								<p v-if="$parent.noxTreeBonuses.bonus_6_details[noxId] && [2,3].includes($parent.noxTreeBonuses.bonus_6_details[noxId].status)" v-html="$store.getters.getLanguageText('5.13', 2, { name: $store.getters.getLanguageText('3.5.5', noxIndex) })"></p>
								<p v-html="$store.getters.getLanguageText('5.13', 3, { days: $store.state.noxSystemSettings.period_for_bonus_6, name: $store.getters.getLanguageText('3.5.5', noxIndex), count: $store.state.noxSystemSettings['count_cycles_for_receive_gift' + noxId] })"></p>
								<p v-if="(noxId + 1) <= $store.state.noxSystemSettings.count_gifts_for_bonus_6" v-html="$store.getters.getLanguageText('5.13', 4, { days: $store.state.noxSystemSettings.period_for_bonus_6, name: $store.getters.getLanguageText('3.5.5', (noxIndex + 1)), count: $store.state.noxSystemSettings['count_cycles_for_receive_gift' + (noxId + 1)] })"></p>
								<div v-html="$store.getters.getLanguageText('5.13', 5, { days: $store.state.noxSystemSettings.period_for_bonus_6, name: $store.getters.getLanguageText('3.5.5', noxIndex), name_1: $store.getters.getLanguageText('3.5.5', 0), name_2: $store.getters.getLanguageText('3.5.5', 1), name_3: $store.getters.getLanguageText('3.5.5', 2), name_4: $store.getters.getLanguageText('3.5.5', 3), count_1: $store.state.noxSystemSettings.count_cycles_for_receive_gift1, count_2: $store.state.noxSystemSettings.count_cycles_for_receive_gift2, count_3: $store.state.noxSystemSettings.count_cycles_for_receive_gift3, count_4: $store.state.noxSystemSettings.count_cycles_for_receive_gift4, status: (($parent.noxTreeBonuses.bonus_6_id == noxId && $parent.noxTreeBonuses.bonus_6_finish) ? $store.getters.getLanguageText('3.5.6', 0) : ($parent.noxTreeBonuses.bonus_6_id > noxId ? ['', $store.getters.getLanguageText('3.5.6', 2), $store.getters.getLanguageText('3.5.6', 3), $store.getters.getLanguageText('3.5.6', 4), $store.getters.getLanguageText('3.5.6', 5)][$parent.noxTreeBonuses.bonus_6_details[noxId].status] : $store.getters.getLanguageText('3.5.6', 1))) })"></div>
								<div v-if="$parent.noxTreeBonuses.bonus_6_id > noxId || ($parent.noxTreeBonuses.bonus_6_id == noxId && $parent.noxTreeBonuses.bonus_6_finish)" v-html="$store.getters.getLanguageText('5.13', 6, { count_1: ($parent.noxTreeBonuses.bonus_6_id > noxId ? $store.state.noxSystemSettings['count_cycles_for_receive_gift' + noxId] : $parent.noxTreeBonuses.bonus_6_points), count_2: $store.state.noxSystemSettings['count_cycles_for_receive_gift' + noxId] })"></div>
								<div v-if="$parent.noxTreeBonuses.bonus_6_id == noxId && $parent.noxTreeBonuses.bonus_6_finish" v-html="$store.getters.getLanguageText('5.13', 7, { days: $store.state.noxSystemSettings.period_for_bonus_6, days_left: ($parent.noxTreeBonuses.bonus_6_finish > ((new Date().getTime()) / 1000) ? parseInt(($parent.noxTreeBonuses.bonus_6_finish - ((new Date().getTime()) / 1000)) / (24 * 60 * 60)) : 0) })"></div>
							</div>
						</div>
						<div v-else-if="noxType == 'receiveGift'">
							<div v-if="noxStep == 1">
								<p v-html="$store.getters.getLanguageText('5.13', 9, { name: $store.getters.getLanguageText('3.5.5', noxIndex) })"></p>
								<p v-if="(noxId + 1) <= $store.state.noxSystemSettings.count_gifts_for_bonus_6" v-html="$store.getters.getLanguageText('5.13', 10, { days: $store.state.noxSystemSettings.period_for_bonus_6, time: (new Date($parent.noxTreeBonuses.bonus_6_details[noxId].time * 1000).toLocaleString($store.state.noxLanguage)), name_1: $store.getters.getLanguageText('3.5.5', noxIndex), name_2: $store.getters.getLanguageText('3.5.5', (noxIndex + 1)), count: $store.state.noxSystemSettings['count_cycles_for_receive_gift' + (noxId + 1)] })"></p>
								<p v-if="noxId == $store.state.noxSystemSettings.count_gifts_for_bonus_6" v-html="$store.getters.getLanguageText('5.13', 11)"></p>
								<p v-html="$store.getters.getLanguageText('5.13', 12, { name: $store.getters.getLanguageText('3.5.5', noxIndex) })"></p>
							</div>
							<div v-else-if="noxStep == 2">
								<div class="nox_modal_info_row">
									<div class="nox_modal_info_row_label" v-html="$store.getters.getLanguageText('5.13', 13)"></div>
									<div class="nox_modal_info_row_value">
										<div class="nox_modal_info_row_value_text" v-html="$store.getters.getLanguageText('3.5.5', noxIndex)"></div>
									</div>
								</div>
								<div class="nox_modal_info_row">
									<div class="nox_modal_info_row_label" v-html="$store.getters.getLanguageText('5.13', 14)"></div>
									<div class="nox_modal_info_row_value">
										<div class="nox_modal_info_row_value_text">{{ new Date($parent.noxTreeBonuses.bonus_6_details[noxId].time * 1000).toLocaleString($store.state.noxLanguage) }}</div>
									</div>
								</div>
								<div class="nox_modal_info_row margin_top_20">
									<div class="nox_modal_info_row_label" v-html="$store.getters.getLanguageText('5.13', 15)"></div>
									<div class="nox_modal_info_row_value">
										<input type="text" class="nox_input" v-model.trim="noxName" :placeholder="$store.getters.getLanguageText('5.13', 16)" autocomplete="off">
										<div v-html="noxAlertName"></div>
									</div>
								</div>
								<div class="nox_modal_info_row">
									<div class="nox_modal_info_row_label" v-html="$store.getters.getLanguageText('5.13', 17)"></div>
									<div class="nox_modal_info_row_value">
										<input type="text" class="nox_input" v-model.trim="noxSurname" :placeholder="$store.getters.getLanguageText('5.13', 18)" autocomplete="off">
										<div v-html="noxAlertSurname"></div>
									</div>
								</div>
								<div class="nox_modal_info_row">
									<div class="nox_modal_info_row_label" v-html="$store.getters.getLanguageText('5.13', 19)"></div>
									<div class="nox_modal_info_row_value">
										<input type="text" class="nox_input" v-model.trim="noxMiddlename" :placeholder="$store.getters.getLanguageText('5.13', 20)" autocomplete="off">
										<div v-html="noxAlertMiddlename"></div>
									</div>
								</div>
								<div class="nox_modal_info_row">
									<div class="nox_modal_info_row_label" v-html="$store.getters.getLanguageText('5.13', 21)"></div>
									<div class="nox_modal_info_row_value">
										<input type="text" class="nox_input" v-model.trim="noxEmail" :placeholder="$store.getters.getLanguageText('5.13', 22)" autocomplete="off">
										<div v-html="noxAlertEmail"></div>
									</div>
								</div>
								<div class="nox_modal_info_row">
									<div class="nox_modal_info_row_label" v-html="$store.getters.getLanguageText('5.13', 23)"></div>
									<div class="nox_modal_info_row_value">
										<input type="text" class="nox_input" v-model.trim="noxPhone" :placeholder="$store.getters.getLanguageText('5.13', 24)" autocomplete="off">
										<div v-html="noxAlertPhone"></div>
									</div>
								</div>
								<div class="nox_modal_info_row">
									<div class="nox_modal_info_row_label" v-html="$store.getters.getLanguageText('5.13', 25)"></div>
									<div class="nox_modal_info_row_value">
										<v-select class="nox_select style-chooser" v-model.trim="noxCountry" :placeholder="$store.getters.getLanguageText('5.13', 26)" :options="$parent.$parent.$parent.getSelectOptions(1)"></v-select>
										<div v-html="noxAlertCountry"></div>
									</div>
								</div>
								<div class="nox_modal_info_row">
									<div class="nox_modal_info_row_label" v-html="$store.getters.getLanguageText('5.13', 27)"></div>
									<div class="nox_modal_info_row_value">
										<input type="text" class="nox_input" v-model.trim="noxState" :placeholder="$store.getters.getLanguageText('5.13', 28)" autocomplete="off">
										<div v-html="noxAlertState"></div>
									</div>
								</div>
								<div class="nox_modal_info_row">
									<div class="nox_modal_info_row_label" v-html="$store.getters.getLanguageText('5.13', 29)"></div>
									<div class="nox_modal_info_row_value">
										<input type="text" class="nox_input" v-model.trim="noxCity" :placeholder="$store.getters.getLanguageText('5.13', 30)" autocomplete="off">
										<div v-html="noxAlertCity"></div>
									</div>
								</div>
								<div class="nox_modal_info_row">
									<div class="nox_modal_info_row_label" v-html="$store.getters.getLanguageText('5.13', 31)"></div>
									<div class="nox_modal_info_row_value">
										<input type="text" class="nox_input" v-model.trim="noxStreet" :placeholder="$store.getters.getLanguageText('5.13', 32)" autocomplete="off">
										<div v-html="noxAlertStreet"></div>
									</div>
								</div>
								<div class="nox_modal_info_row">
									<div class="nox_modal_info_row_label" v-html="$store.getters.getLanguageText('5.13', 33)"></div>
									<div class="nox_modal_info_row_value">
										<input type="text" class="nox_input" v-model.trim="noxHouse" :placeholder="$store.getters.getLanguageText('5.13', 34)" autocomplete="off">
										<div v-html="noxAlertHouse"></div>
									</div>
								</div>
								<div class="nox_modal_info_row">
									<div class="nox_modal_info_row_label" v-html="$store.getters.getLanguageText('5.13', 35)"></div>
									<div class="nox_modal_info_row_value">
										<input type="text" class="nox_input" v-model.trim="noxHousing" :placeholder="$store.getters.getLanguageText('5.13', 36)" autocomplete="off">
										<div v-html="noxAlertHousing"></div>
									</div>
								</div>
								<div class="nox_modal_info_row">
									<div class="nox_modal_info_row_label" v-html="$store.getters.getLanguageText('5.13', 37)"></div>
									<div class="nox_modal_info_row_value">
										<input type="text" class="nox_input" v-model.trim="noxApartment" :placeholder="$store.getters.getLanguageText('5.13', 38)" autocomplete="off">
										<div v-html="noxAlertApartment"></div>
									</div>
								</div>
								<div class="nox_modal_info_row">
									<div class="nox_modal_info_row_label" v-html="$store.getters.getLanguageText('5.13', 39)"></div>
									<div class="nox_modal_info_row_value">
										<input type="text" class="nox_input" v-model.trim="noxPostcode" :placeholder="$store.getters.getLanguageText('5.13', 40)" autocomplete="off">
										<div v-html="noxAlertPostcode"></div>
									</div>
								</div>
								<div class="nox_modal_info_row">
									<div class="nox_modal_info_row_label" v-html="$store.getters.getLanguageText('5.13', 41)"></div>
									<div class="nox_modal_info_row_value">
										<textarea class="nox_textarea small" v-model.trim="noxComment" :placeholder="$store.getters.getLanguageText('5.13', 42)"></textarea>
										<div v-html="noxAlertComment"></div>
									</div>
								</div>
								<div class="nox_modal_info_row margin_top_20">
									<label class="nox_checkbox" for="agreement1">
										<input type="checkbox" id="agreement1" v-model.trim="noxIsAgreement1">
										<span class="nox_checkbox_mark"></span>
										<span class="nox_checkbox_text" v-html="$store.getters.getLanguageText('5.13', 43)"></span>
									</label>
									<div v-html="noxAlertIsAgreement1"></div>
								</div>
								<div class="nox_modal_info_row last">
									<label class="nox_checkbox" for="agreement2">
										<input type="checkbox" id="agreement2" v-model.trim="noxIsAgreement2">
										<span class="nox_checkbox_mark"></span>
										<span class="nox_checkbox_text" v-html="$store.getters.getLanguageText('5.13', 44)"></span>
									</label>
									<div v-html="noxAlertIsAgreement2"></div>
								</div>
							</div>
							<div v-else-if="noxStep == 3" v-html="$store.getters.getLanguageText('5.13', 45, { email: noxEmail, phone: noxPhone })"></div>
						</div>
						<div v-else-if="noxType == 'deliveryGift'" v-html="$store.getters.getLanguageText('5.13', 47, { name: $store.getters.getLanguageText('3.5.5', noxIndex), step_1: ($parent.noxTreeBonuses.bonus_6_details[noxId].status >= 2 ? $store.getters.getLanguageText('5.13', 48) : ''), step_2: ($parent.noxTreeBonuses.bonus_6_details[noxId].status >= 3 ? $store.getters.getLanguageText('5.13', 48) : ''), step_3: ($parent.noxTreeBonuses.bonus_6_details[noxId].status >= 3 ? $store.getters.getLanguageText('5.13', 48) : ''), step_4: ($parent.noxTreeBonuses.bonus_6_details[noxId].status >= 4 ? $store.getters.getLanguageText('5.13', 48) : '') })"></div>
					</div>
					<div class="nox_modal_alert" v-html="noxAlert"></div>
					<div class="nox_modal_loading" :class="{ hide: !noxIsLoading }"><img src="@/assets/images/loading.gif"></div>
					<div class="nox_modal_buttons" :class="{ hide: noxIsLoading }">
						<button type="button" class="nox_button common" @click="close" v-if="noxType == 'receiveGift' && noxStep == 1" v-html="$store.getters.getLanguageText('1.1', 75)"></button>
						<button type="button" class="nox_button common" @click="back" v-else-if="noxType == 'receiveGift' && noxStep == 2" v-html="$store.getters.getLanguageText('1.1', 77)"></button>
						<button type="button" class="nox_button common" @click="close" v-else v-html="$store.getters.getLanguageText('1.1', 3)"></button>
						<button type="button" class="nox_button common green" @click="axios('editGift')" v-if="noxType == 'editGift'" v-html="$store.getters.getLanguageText('1.1', 7)"></button>
						<button type="button" class="nox_button common green" @click="axios('receiveGift')" v-else-if="noxType == 'receiveGift' && noxStep == 1" v-html="$store.getters.getLanguageText('1.1', 76)"></button>
						<button type="submit" class="nox_button common green" @click="submit($event, 'receiveGift')" v-else-if="noxType == 'receiveGift' && noxStep == 2" v-html="$store.getters.getLanguageText('1.1', 78)"></button>
					</div>
				</form>
			</div>
		</div>
	</modal>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxTemp: '',
			noxData: {},
			noxAlert: '',
			noxAlertCdekId: '',
			noxAlertName: '',
			noxAlertSurname: '',
			noxAlertMiddlename: '',
			noxAlertEmail: '',
			noxAlertPhone: '',
			noxAlertCountry: '',
			noxAlertState: '',
			noxAlertCity: '',
			noxAlertStreet: '',
			noxAlertHouse: '',
			noxAlertHousing: '',
			noxAlertApartment: '',
			noxAlertPostcode: '',
			noxAlertComment: '',
			noxAlertStatus: '',
			noxAlertIsAgreement1: '',
			noxAlertIsAgreement2: '',
			noxStep: 0,
			noxId: 0,
			noxType: '',
			noxIndex: 0,
			noxGiftId: 0,
			noxUserId: 0,
			noxCdekId: '',
			noxName: '',
			noxSurname: '',
			noxMiddlename: '',
			noxEmail: '',
			noxPhone: '',
			noxCountry: '',
			noxState: '',
			noxCity: '',
			noxStreet: '',
			noxHouse: '',
			noxHousing: '',
			noxApartment: '',
			noxPostcode: '',
			noxComment: '',
			noxStatus: '',
			noxClosedAt: 0,
			noxCreatedAt: 0,
			noxIsAgreement1: false,
			noxIsAgreement2: false,
			noxIsLoading: false,
			noxIsError: false
		}),
		methods: {
			beforeOpen: function(event) {

				this.noxTemp = '';
				this.noxData = {};
				this.resetAlerts();
				this.noxStep = 1;
				this.noxId = event.params.id;
				this.noxType = event.params.type;
				this.noxIndex = event.params.index;
				this.noxGiftId = 0;
				this.noxUserId = 0;
				this.noxCdekId = '';
				this.noxName = '';
				this.noxSurname = '';
				this.noxMiddlename = '';
				this.noxEmail = '';
				this.noxPhone = '';
				this.noxCountry = '';
				this.noxState = '';
				this.noxCity = '';
				this.noxStreet = '';
				this.noxHouse = '';
				this.noxHousing = '';
				this.noxApartment = '';
				this.noxPostcode = '';
				this.noxComment = '';
				this.noxStatus = '';
				this.noxClosedAt = 0;
				this.noxCreatedAt = 0;
				this.noxIsAgreement1 = true;
				this.noxIsAgreement2 = true;
				this.noxIsLoading = false;
				this.noxIsError = false;

				if (this.noxType == 'editGift') {

					this.noxData = this.$parent.noxDeliveryData[this.noxIndex];
					this.noxGiftId = this.noxData.gift_id;
					this.noxUserId = this.noxData.user_id;
					this.noxCdekId = this.noxData.cdek_id ? this.noxData.cdek_id : '';
					this.noxName = this.noxData.name;
					this.noxSurname = this.noxData.surname;
					this.noxMiddlename = this.noxData.middlename;
					this.noxEmail = this.noxData.email;
					this.noxPhone = ((this.noxData.phone ? '+' : '') + this.noxData.phone);
					this.noxState = this.noxData.state;
					this.noxCity = this.noxData.city;
					this.noxStreet = this.noxData.street;
					this.noxHouse = this.noxData.house;
					this.noxHousing = this.noxData.housing;
					this.noxApartment = this.noxData.apartment;
					this.noxPostcode = this.noxData.postcode;
					this.noxComment = this.noxData.comment;
					this.noxClosedAt = this.noxData.closed_at;
					this.noxCreatedAt = this.noxData.created_at;

					for (var i in this.$parent.$parent.$parent.getSelectOptions(1)) {
						if (this.$parent.$parent.$parent.getSelectOptions(1)[i].code == this.noxData.country) {
							this.noxCountry = this.$parent.$parent.$parent.getSelectOptions(1)[i]; break;
						}
					}
					for (var j in this.$parent.$parent.$parent.getSelectOptions(6)) {
						if (this.$parent.$parent.$parent.getSelectOptions(6)[j].code == this.noxData.status) {
							this.noxStatus = this.$parent.$parent.$parent.getSelectOptions(6)[j]; break;
						}
					}
				}
			},
			resetAlerts: function() {

				this.noxAlert = '';
				this.noxAlertCdekId = '';
				this.noxAlertName = '';
				this.noxAlertSurname = '';
				this.noxAlertMiddlename = '';
				this.noxAlertEmail = '';
				this.noxAlertPhone = '';
				this.noxAlertCountry = '';
				this.noxAlertState = '';
				this.noxAlertCity = '';
				this.noxAlertStreet = '';
				this.noxAlertHouse = '';
				this.noxAlertHousing = '';
				this.noxAlertApartment = '';
				this.noxAlertPostcode = '';
				this.noxAlertComment = '';
				this.noxAlertStatus = '';
				this.noxAlertIsAgreement1 = '';
				this.noxAlertIsAgreement2 = '';
			},
			getError: function(i) {

				this.noxIsError = true;

				if      (i == 1) { this.noxTemp = this.$store.getters.getLanguageText('1.3.15', 0); }
				else if (i == 2) { this.noxTemp = this.$store.getters.getLanguageText('1.3.15', 1); }
				else if (i == 3) { this.noxTemp = this.$store.getters.getLanguageText('1.3.15', 2); }
				else if (i == 4) { this.noxTemp = this.$store.getters.getLanguageText('1.3.15', 3); }
				else if (i == 5) { this.noxTemp = this.$store.getters.getLanguageText('1.3.15', 4); }
				else if (i == 6) { this.noxTemp = this.$store.getters.getLanguageText('1.3.15', 5); }
				else if (i == 7) { this.noxTemp = this.$store.getters.getLanguageText('1.3.15', 6); }
				else if (i == 8) { this.noxTemp = this.$store.getters.getLanguageText('1.3.15', 7); }
				else if (i == 9) { this.noxTemp = this.$store.getters.getLanguageText('1.3.15', 8); }
				else if (i == 10) { this.noxTemp = this.$store.getters.getLanguageText('1.3.15', 9); }
				else if (i == 11) { this.noxTemp = this.$store.getters.getLanguageText('1.3.15', 10); }
				else if (i == 12) { this.noxTemp = this.$store.getters.getLanguageText('1.3.15', 11); }
				else if (i == 13) { this.noxTemp = this.$store.getters.getLanguageText('1.3.15', 12); }
				else if (i == 14) { this.noxTemp = this.$store.getters.getLanguageText('1.3.15', 13); }
				else if (i == 15) { this.noxTemp = this.$store.getters.getLanguageText('1.3.15', 14); }
				else if (i == 16) { this.noxTemp = this.$store.getters.getLanguageText('1.3.15', 15); }
				else if (i == 17) { this.noxTemp = this.$store.getters.getLanguageText('1.3.15', 16); }
				else if (i == 18) { this.noxTemp = this.$store.getters.getLanguageText('1.3.15', 17); }
				else if (i == 19) { this.noxTemp = this.$store.getters.getLanguageText('1.3.15', 18); }
				else if (i == 20) { this.noxTemp = this.$store.getters.getLanguageText('1.3.15', 19); }
				else if (i == 21) { this.noxTemp = this.$store.getters.getLanguageText('1.3.15', 20); }
				else if (i == 22) { this.noxTemp = this.$store.getters.getLanguageText('1.3.15', 21); }
				else if (i == 23) { this.noxTemp = this.$store.getters.getLanguageText('1.3.15', 22); }
				else if (i == 24) { this.noxTemp = this.$store.getters.getLanguageText('1.3.15', 23); }
				else if (i == 25) { this.noxTemp = this.$store.getters.getLanguageText('1.3.15', 24); }
				else if (i == 26) { this.noxTemp = this.$store.getters.getLanguageText('1.3.15', 25); }
				else if (i == 27) { this.noxTemp = this.$store.getters.getLanguageText('1.3.15', 26); }
				else if (i == 28) { this.noxTemp = this.$store.getters.getLanguageText('1.3.15', 27); }
				else if (i == 29) { this.noxTemp = this.$store.getters.getLanguageText('1.3.15', 28); }
				else if (i == 30) { this.noxTemp = this.$store.getters.getLanguageText('1.3.15', 29); }
				else if (i == 31) { this.noxTemp = this.$store.getters.getLanguageText('1.3.15', 30); }
				else if (i == 32) { this.noxTemp = this.$store.getters.getLanguageText('1.3.15', 31); }
				else if (i == 33) { this.noxTemp = this.$store.getters.getLanguageText('1.3.15', 32); }
				else if (i == 34) { this.noxTemp = this.$store.getters.getLanguageText('1.3.15', 33); }
				else if (i == 35) { this.noxTemp = this.$store.getters.getLanguageText('1.3.15', 34); }
				else if (i == 36) { this.noxTemp = this.$store.getters.getLanguageText('1.3.15', 35); }
				else if (i == 37) { this.noxTemp = this.$store.getters.getLanguageText('1.3.15', 36); }
				else if (i == 38) { this.noxTemp = 'Необходимо указать id заявки.'; }
				else if (i == 39) { this.noxTemp = 'Необходимо указать верный id заявки.'; }
				else if (i == 40) { this.noxTemp = 'Необходимо указать номер заказа CDEK.'; }
				else if (i == 41) { this.noxTemp = 'Необходимо указать верный номер заказа CDEK.'; }
				else if (i == 42) { this.noxTemp = 'Необходимо выбрать статус заявки.'; }
				else if (i == 43) { this.noxTemp = 'Необходимо выбрать верный статус заявки.'; }
				else if (i == 44) { this.noxTemp = 'Id заявки не найдена в системе.'; }
				else              { this.noxTemp = this.$store.getters.getLanguageText('1.3', 2); }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			submit: function(event, type) {
				event.preventDefault(); this.axios(type);
			},
			axios: function(type) {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					_this.resetAlerts();
					_this.noxIsError = false;

					if (['editGift', 'receiveGift'].includes(type)) {
						if (type == 'receiveGift' && _this.noxStep == 1) {
							config.url = '/v2/account/gifts/form';
							config.params = { gift_id: _this.noxId };
						}
						else {
							if (type == 'editGift') {
								if (!_this.noxCdekId) {
									_this.noxAlertCdekId = _this.getError(40);
								}
								else if (!_this.$store.state.noxRegex_d.test(_this.noxCdekId) || _this.noxCdekId < 1) {
									_this.noxAlertCdekId = _this.getError(41);
								}
								if (!_this.noxStatus) {
									_this.noxAlertStatus = _this.getError(42);
								}
							}
							if (!_this.noxName) {
								_this.noxAlertName = _this.getError(3);
							}
							else if (!_this.$store.state.noxRegexENRULetters.test(_this.noxName)) {
								_this.noxAlertName = _this.getError(4);
							}
							if (!_this.noxSurname) {
								_this.noxAlertSurname = _this.getError(5);
							}
							else if (!_this.$store.state.noxRegexENRULetters.test(_this.noxSurname)) {
								_this.noxAlertSurname = _this.getError(6);
							}
							if (_this.noxMiddlename && !_this.$store.state.noxRegexENRULetters.test(_this.noxMiddlename)) {
								_this.noxAlertMiddlename = _this.getError(8);
							}
							if (!_this.noxEmail) {
								_this.noxAlertEmail = _this.getError(9);
							}
							else if (!_this.$store.state.noxRegexEmail.test(_this.noxEmail)) {
								_this.noxAlertEmail = _this.getError(10);
							}
							if (!_this.noxPhone) {
								_this.noxAlertPhone = _this.getError(11);
							}
							else if (!_this.$store.state.noxRegexPhone.test(_this.noxPhone)) {
								_this.noxAlertPhone = _this.getError(12);
							}
							if (!_this.noxCountry) {
								_this.noxAlertCountry = _this.getError(13);
							}
							if (!_this.noxState) {
								_this.noxAlertState = _this.getError(15);
							}
							else if (!_this.$store.state.noxRegexENRULettersAndNumbersAndMarks.test(_this.noxState)) {
								_this.noxAlertState = _this.getError(16);
							}
							if (!_this.noxCity) {
								_this.noxAlertCity = _this.getError(17);
							}
							else if (!_this.$store.state.noxRegexENRULettersAndNumbersAndMarks.test(_this.noxCity)) {
								_this.noxAlertCity = _this.getError(18);
							}
							if (!_this.noxStreet) {
								_this.noxAlertStreet = _this.getError(19);
							}
							else if (!_this.$store.state.noxRegexENRULettersAndNumbersAndMarks.test(_this.noxStreet)) {
								_this.noxAlertStreet = _this.getError(20);
							}
							if (!_this.noxHouse) {
								_this.noxAlertHouse = _this.getError(21);
							}
							else if (!_this.$store.state.noxRegexENRULettersAndNumbersAndMarks.test(_this.noxHouse)) {
								_this.noxAlertHouse = _this.getError(22);
							}
							if (_this.noxHousing && !_this.$store.state.noxRegexENRULettersAndNumbersAndMarks.test(_this.noxHousing)) {
								_this.noxAlertHousing = _this.getError(24);
							}
							if (!_this.noxApartment) {
								_this.noxAlertApartment = _this.getError(25);
							}
							else if (!_this.$store.state.noxRegexENRULettersAndNumbersAndMarks.test(_this.noxApartment)) {
								_this.noxAlertApartment = _this.getError(26);
							}
							if (!_this.noxPostcode) {
								_this.noxAlertPostcode = _this.getError(27);
							}
							else if (!_this.$store.state.noxRegexENRULettersAndNumbersAndMarks.test(_this.noxPostcode)) {
								_this.noxAlertPostcode = _this.getError(28);
							}
							if (_this.noxComment && !_this.$store.state.noxRegexENRULettersAndNumbersAndMarks.test(_this.noxComment)) {
								_this.noxAlertComment = _this.getError(30);
							}
							if (!_this.noxIsAgreement1) {
								_this.noxAlertIsAgreement1 = _this.getError(33);
							}
							if (!_this.noxIsAgreement2) {
								_this.noxAlertIsAgreement2 = _this.getError(34);
							}
							if (!_this.noxIsError) {
								if (type == 'editGift') {
									config.url = '/v2/admin/account/gifts';
									config.data = { id: _this.noxId, cdek_id: _this.noxCdekId, name: _this.noxName, surname: _this.noxSurname, middlename: _this.noxMiddlename, email: _this.noxEmail, phone: _this.noxPhone, country: _this.noxCountry.code, state: _this.noxState, city: _this.noxCity, street: _this.noxStreet, house: _this.noxHouse, housing: _this.noxHousing, apartment: _this.noxApartment, postcode: _this.noxPostcode, comment: _this.noxComment, status: _this.noxStatus.code };
									config.method = 'patch';
								}
								else if (type == 'receiveGift') {
									config.url = '/v2/account/gifts';
									config.data = { gift_id: _this.noxId, name: _this.noxName, surname: _this.noxSurname, middlename: _this.noxMiddlename, email: _this.noxEmail, phone: _this.noxPhone, country: _this.noxCountry.code, state: _this.noxState, city: _this.noxCity, street: _this.noxStreet, house: _this.noxHouse, housing: _this.noxHousing, apartment: _this.noxApartment, postcode: _this.noxPostcode, comment: _this.noxComment, closed_at: _this.$parent.noxTreeBonuses.bonus_6_details[_this.noxId].time };
									config.method = 'post';
								}
							}
						}
					}

					if (!_this.noxIsError) {
						_this.noxIsLoading = true;
						axios(config).then(function(data) {
							if (data.status == 200) {
								if (type == 'editGift') {
									_this.noxData.cdek_id = _this.noxCdekId;
									if (Number(_this.noxStatus.code) !== Number(_this.noxData.status)) {
										document.location.reload(false); return false;
									}
									_this.close();
								}
								else if (type == 'receiveGift') {
									if (_this.noxStep == 1) {
										_this.noxStep = 2;
										if (data.data !== null) {
											_this.noxName = data.data.name;
											_this.noxSurname = data.data.surname;
											_this.noxMiddlename = data.data.middlename;
											_this.noxEmail = data.data.email;
											_this.noxPhone = ((data.data.phone ? '+' : '') + data.data.phone);
											_this.noxState = data.data.state;
											_this.noxCity = data.data.city;
											_this.noxStreet = data.data.street;
											_this.noxHouse = data.data.house;
											_this.noxHousing = data.data.housing;
											_this.noxApartment = data.data.apartment;
											_this.noxPostcode = data.data.postcode;
											_this.noxComment = '';
											for (var i in _this.$parent.$parent.$parent.getSelectOptions(1)) {
												if (_this.$parent.$parent.$parent.getSelectOptions(1)[i].code == data.data.country) {
													_this.noxCountry = _this.$parent.$parent.$parent.getSelectOptions(1)[i]; break;
												}
											}
										}
									}
									else if (_this.noxStep == 2) {
										_this.noxStep = 3;
										_this.$parent.noxTreeBonuses.bonus_6_details[_this.noxId].status = 2;
									}
								}
							}
						}).catch(function(data) {
							if (data.response !== undefined) {
								if (data.response.status == 400) {
									if (['editGift', 'receiveGift'].includes(type)) {
										if      (data.response.data.error == 'ID_IS_EMPTY') { _this.noxAlert = _this.getError(38); }
										else if (data.response.data.error == 'ID_NOT_VALID') { _this.noxAlert = _this.getError(39); }
										else if (data.response.data.error == 'GIFT_ID_IS_EMPTY') { _this.noxAlert = _this.getError(1); }
										else if (data.response.data.error == 'GIFT_ID_NOT_VALID') { _this.noxAlert = _this.getError(2); }
										else if (data.response.data.error == 'NAME_IS_EMPTY') { _this.noxAlertName = _this.getError(3); }
										else if (data.response.data.error == 'NAME_NOT_VALID') { _this.noxAlertName = _this.getError(4); }
										else if (data.response.data.error == 'SURNAME_IS_EMPTY') { _this.noxAlertSurname = _this.getError(5); }
										else if (data.response.data.error == 'SURNAME_NOT_VALID') { _this.noxAlertSurname = _this.getError(6); }
										else if (data.response.data.error == 'MIDDLENAME_IS_EMPTY') { _this.noxAlertMiddlename = _this.getError(7); }
										else if (data.response.data.error == 'MIDDLENAME_NOT_VALID') { _this.noxAlertMiddlename = _this.getError(8); }
										else if (data.response.data.error == 'EMAIL_IS_EMPTY') { _this.noxAlertEmail = _this.getError(9); }
										else if (data.response.data.error == 'EMAIL_NOT_VALID') { _this.noxAlertEmail = _this.getError(10); }
										else if (data.response.data.error == 'PHONE_IS_EMPTY') { _this.noxAlertPhone = _this.getError(11); }
										else if (data.response.data.error == 'PHONE_NOT_VALID') { _this.noxAlertPhone = _this.getError(12); }
										else if (data.response.data.error == 'COUNTRY_IS_EMPTY') { _this.noxAlertCountry = _this.getError(13); }
										else if (data.response.data.error == 'COUNTRY_NOT_VALID') { _this.noxAlertCountry = _this.getError(14); }
										else if (data.response.data.error == 'STATE_IS_EMPTY') { _this.noxAlertState = _this.getError(15); }
										else if (data.response.data.error == 'STATE_NOT_VALID') { _this.noxAlertState = _this.getError(16); }
										else if (data.response.data.error == 'CITY_IS_EMPTY') { _this.noxAlertCity = _this.getError(17); }
										else if (data.response.data.error == 'CITY_NOT_VALID') { _this.noxAlertCity = _this.getError(18); }
										else if (data.response.data.error == 'STREET_IS_EMPTY') { _this.noxAlertStreet = _this.getError(19); }
										else if (data.response.data.error == 'STREET_NOT_VALID') { _this.noxAlertStreet = _this.getError(20); }
										else if (data.response.data.error == 'HOUSE_IS_EMPTY') { _this.noxAlertHouse = _this.getError(21); }
										else if (data.response.data.error == 'HOUSE_NOT_VALID') { _this.noxAlertHouse = _this.getError(22); }
										else if (data.response.data.error == 'HOUSING_IS_EMPTY') { _this.noxAlertHousing = _this.getError(23); }
										else if (data.response.data.error == 'HOUSING_NOT_VALID') { _this.noxAlertHousing = _this.getError(24); }
										else if (data.response.data.error == 'APARTMENT_IS_EMPTY') { _this.noxAlertApartment = _this.getError(25); }
										else if (data.response.data.error == 'APARTMENT_NOT_VALID') { _this.noxAlertApartment = _this.getError(26); }
										else if (data.response.data.error == 'POSTCODE_IS_EMPTY') { _this.noxAlertPostcode = _this.getError(27); }
										else if (data.response.data.error == 'POSTCODE_NOT_VALID') { _this.noxAlertPostcode = _this.getError(28); }
										else if (data.response.data.error == 'COMMENT_IS_EMPTY') { _this.noxAlertComment = _this.getError(29); }
										else if (data.response.data.error == 'COMMENT_NOT_VALID') { _this.noxAlertComment = _this.getError(30); }
										else if (data.response.data.error == 'CDEK_ID_IS_EMPTY') { _this.noxAlertCdekId = _this.getError(40); }
										else if (data.response.data.error == 'CDEK_ID_NOT_VALID') { _this.noxAlertCdekId = _this.getError(41); }
										else if (data.response.data.error == 'STATUS_IS_EMPTY') { _this.noxAlertStatus = _this.getError(42); }
										else if (data.response.data.error == 'STATUS_NOT_VALID') { _this.noxAlertStatus = _this.getError(43); }
										else if (data.response.data.error == 'CLOSED_AT_IS_EMPTY') { _this.noxAlert = _this.getError(31); }
										else if (data.response.data.error == 'CLOSED_AT_NOT_VALID') { _this.noxAlert = _this.getError(32); }
									}
								}
								else if (data.response.status == 401) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 403) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 404) {
									if (type == 'editGift') {
										if (data.response.data.error == 'ID_NOT_FOUND') { _this.noxAlert = _this.getError(44); }
									}
								}
								else if (data.response.status == 409) {
									if (type == 'receiveGift') {
										if      (data.response.data.error == 'ALREADY_ON_MODERATION') { _this.noxAlert = _this.getError(35); }
										else if (data.response.data.error == 'ALREADY_ON_DISPATCH') { _this.noxAlert = _this.getError(36); }
										else if (data.response.data.error == 'ALREADY_DELIVERED') { _this.noxAlert = _this.getError(37); }
									}
								}
							}
							if (!_this.noxIsError) {
								_this.noxAlert = _this.getError(0);
							}
						}).finally(function() {
							if (type == 'editGift') { return false; }
							_this.noxIsLoading = false;
						});
					}
				}
				else {
					_this.$parent.$parent.$parent.exit();
				}
			},
			back: function() {
				this.noxAlert = '';
				this.noxStep = 1;
			},
			close: function() {
				this.$modal.hide('NoxModalTreeGifts');
			}
		}
	}
</script>
